import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 32 32" {...props}>
      <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm4.2 25.6c-.3.1-.7.2-1.1.2-1.4 0-2.5-.5-3.2-1.4-.8-.9-2.2-2.8-3.6-5.4h-1.8v4c0 1.5.3 1.8 1 1.9l1.2.2v.7c-.6 0-2-.1-3-.1-1.1 0-2.2 0-2.9.1v-.7l.9-.2c.6-.1 1-.4 1-1.9v-9.3c0-1.5-.4-1.8-1-1.9l-.9-.2v-.7h2.5c1 0 2.2-.1 3.6-.1 2.7 0 4.8 1.2 4.8 3.9 0 1.8-1.4 3.6-3.3 4.1 1.2 2.1 2.6 3.9 3.6 5 .6.7 1.5 1.3 2.3 1.3v.5zm5-15.1v1c0 .3-.1.6-.1.8-.2.6-.5 1-1 1.4-.5.3-1.1.5-1.7.5-.7 0-1.3-.2-1.7-.5-.5-.3-.8-.8-1-1.4 0-.1-.1-.2-.1-.4 0-.1 0-.3-.1-.4v-.6-.8-.8-.6c0-.2 0-.3.1-.4 0-.1.1-.2.1-.4.2-.6.5-1.1 1-1.4.5-.3 1-.5 1.7-.5.3 0 .7 0 .9.1.3.1.5.2.7.3.2.1.4.3.6.5.2.2.3.4.4.6v.2L24 8c-.1 0-.1 0-.2-.1-.2-.4-.4-.6-.6-.8-.2-.1-.5-.2-.9-.2-.5 0-.7.1-.9.3-.3.2-.5.5-.6.8 0 .1 0 .2-.1.3v3.4c0 .1 0 .2.1.3.1.4.3.6.6.8.3.2.5.3 1 .3.4 0 .8-.1 1-.3.2-.2.4-.4.5-.7 0-.1.1-.3.1-.5v-.7s0-.1-.1-.1h-1.4c-.1 0-.1 0-.1-.1v-.9c0-.1 0-.1.1-.1H25c.1 0 .1 0 .1.1v.7z" />
      <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm4.2 25.6c-.3.1-.7.2-1.1.2-1.4 0-2.5-.5-3.2-1.4-.8-.9-2.2-2.8-3.6-5.4h-1.8v4c0 1.5.3 1.8 1 1.9l1.2.2v.7c-.6 0-2-.1-3-.1-1.1 0-2.2 0-2.9.1v-.7l.9-.2c.6-.1 1-.4 1-1.9v-9.3c0-1.5-.4-1.8-1-1.9l-.9-.2v-.7h2.5c1 0 2.2-.1 3.6-.1 2.7 0 4.8 1.2 4.8 3.9 0 1.8-1.4 3.6-3.3 4.1 1.2 2.1 2.6 3.9 3.6 5 .6.7 1.5 1.3 2.3 1.3v.5zm5-15.1v1c0 .3-.1.6-.1.8-.2.6-.5 1-1 1.4-.5.3-1.1.5-1.7.5-.7 0-1.3-.2-1.7-.5-.5-.3-.8-.8-1-1.4 0-.1-.1-.2-.1-.4 0-.1 0-.3-.1-.4v-.6-.8-.8-.6c0-.2 0-.3.1-.4 0-.1.1-.2.1-.4.2-.6.5-1.1 1-1.4.5-.3 1-.5 1.7-.5.3 0 .7 0 .9.1.3.1.5.2.7.3.2.1.4.3.6.5.2.2.3.4.4.6v.2L24 8c-.1 0-.1 0-.2-.1-.2-.4-.4-.6-.6-.8-.2-.1-.5-.2-.9-.2-.5 0-.7.1-.9.3-.3.2-.5.5-.6.8 0 .1 0 .2-.1.3v3.4c0 .1 0 .2.1.3.1.4.3.6.6.8.3.2.5.3 1 .3.4 0 .8-.1 1-.3.2-.2.4-.4.5-.7 0-.1.1-.3.1-.5v-.7s0-.1-.1-.1h-1.4c-.1 0-.1 0-.1-.1v-.9c0-.1 0-.1.1-.1H25c.1 0 .1 0 .1.1v.7z" />
      <g>
        <path d="M15.6 14.7c0-1.9-1.2-2.9-3.2-2.9h-1.9v6c.5.1.9.1 1.7.1 2.1 0 3.4-1.3 3.4-3.2z" />
        <path d="M15.6 14.7c0-1.9-1.2-2.9-3.2-2.9h-1.9v6c.5.1.9.1 1.7.1 2.1 0 3.4-1.3 3.4-3.2z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
