import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 32 32" {...props}>
      <path d="M16.4 19.1h-.5c-.5 0-1 0-1.4.1-.5.1-.9.2-1.4.4-.5.2-.8.5-1.1.9-.3.4-.4.8-.4 1.3s.1.9.4 1.3c.2.4.6.7 1 .9.4.2.8.4 1.3.5.4.1.9.2 1.4.2.9 0 1.7-.2 2.3-.6.7-.4 1-1 1-1.9 0-.2 0-.4-.1-.5-.1-.2-.1-.3-.2-.4-.1-.1-.1-.3-.3-.4-.1-.2-.2-.3-.3-.4l-.4-.4c-.2-.2-.3-.3-.4-.3-.1 0-.2-.1-.4-.3-.4-.3-.5-.4-.5-.4zM16.3 9.5c-.5-.5-1-.8-1.6-.8-.7 0-1.2.2-1.5.7-.4.5-.5 1.1-.5 1.7 0 .6.1 1.2.3 1.8.2.6.5 1.1 1 1.6.4.5 1 .7 1.6.7.7 0 1.2-.2 1.5-.7.4-.4.6-1 .6-1.7 0-.6-.1-1.2-.3-1.8-.3-.5-.6-1-1.1-1.5z" />
      <path d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm6.9 9.1v6.4c0 .3-.2.5-.5.5h-.2c-.3 0-.5-.2-.5-.5V9.1c0-.3 0-.5.3-.5v-.9L19.1 10c0 .1.1.1.1.2.2.4.4 1 .4 1.7 0 .5-.1 1-.3 1.4-.2.4-.4.7-.6 1-.2.3-.5.5-.7.7s-.5.4-.6.7c-.2.2-.3.5-.3.7s.1.5.3.7c.2.3.5.5.8.7.3.2.7.5 1 .8.3.3.6.7.8 1.1.2.5.3 1 .3 1.5 0 .7-.2 1.4-.6 2-.4.6-.9 1-1.4 1.4-.6.3-1.2.6-1.9.8-.7.2-1.4.3-2 .3-.4 0-.9 0-1.3-.1s-.9-.2-1.3-.4l-1.2-.6c-.3-.2-.6-.6-.8-.9s-.3-.8-.3-1.3c0-.6.2-1.1.5-1.6s.7-.9 1.3-1.2c.9-.6 2.4-.9 4.3-1.1-.4-.6-.7-1.1-.7-1.6 0-.3.1-.6.2-.9-.2 0-.5.1-.7.1-1.1 0-1.9-.3-2.7-1-.7-.7-1.1-1.5-1.1-2.6v-.3H6.4l6.5-5.6h10.7l-1 .8v1.4c.3-.2.3 0 .3.3z" />
    </svg>
  );
}

export default SvgComponent;
