/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );

  const metaDescription =
    description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          // content: `summary`,
          content: `summary_large_image`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/* <!-- Primary Meta Tags --> */}
      {/* <title>{title}</title> */}
      {/* <meta name="title" content={title} /> */}
      {/* <meta name="description" content={description} /> */}

      {/* <!-- Open Graph / Facebook --> */}
      {/* <meta property="og:type" content="website" /> */}
      {/* <meta
        property="og:url"
        content="https://helloimrui.com/"
      /> */}
      {/* <meta property="og:title" content={title} /> */}
      {/* <meta
        property="og:description"
        content={description}
      /> */}
      <meta
        property="og:image"
        content="https://helloimrui.surge.sh/rui-new-york.jpg"
      />

      {/* <!-- Twitter --> */}
      <meta
        property="twitter:card"
        content="summary_large_image"
      />
      {/* <meta
        property="twitter:url"
        content="https://helloimrui.com/"
      /> */}
      {/* <meta property="twitter:title" content="Home" /> */}
      {/* <meta
        property="twitter:description"
        content={description}
      /> */}
      <meta
        property="twitter:image"
        content="https://helloimrui.surge.sh/rui-new-york.jpg"
      ></meta>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
